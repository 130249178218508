import React, { useState, useContext } from "react";
import { usersPathTo, providersPathTo } from "../utils/Api";
import Button from '@mui/material/Button';
import SnackbarContext from "../contexts/SnackbarContext";
import FormBox from '../components/building_blocks/FormBox';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';


const NewProvider = () => {
  const [user, setUser] = useState({
    email: '',
    password: '',
    name: ''
  });
  const [userSuccess, setUserSuccess] = useState(false);

  const [provider, setProvider] = useState({
    name: '',
    cnpj: '',
    address: '',
    phone: '',
    description: ''
  });

  const { openSnackbar } = useContext(SnackbarContext);

  const handleUserInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleProviderInputChange = (event) => {
    const { name, value } = event.target;
    setProvider({ ...provider, [name]: value });
  };

  const handleSubmitUser = (event) => {
    event.preventDefault();
    usersPathTo.signup(user).then(result => {
      openSnackbar("Usuário cadastrado com sucesso. Enviamos um e-mail para confirmação da conta", "success");
      setUserSuccess(true);
      // console.log("create_user", result.data);
      setProvider({account_id: result.data.account_id})
    }, error => {})
  };

  const handleSubmitProvider = (event) => {
    event.preventDefault();
    providersPathTo.save(provider).then(result => {
      setProvider({
        account_id: '',
        cnpj: '',
        address: '',
        phone: '',
        description: ''
      });

      openSnackbar("Cadastro realizado com sucesso", "success");

      // after success, reset all data
      setUserSuccess(false);
      setUser({email: '', password: '', name: ''});
      setProvider({
        account_id: '',
        cnpj: '',
        address: '',
        phone: '',
        description: ''
      });

    }, error => {
      console.log("create_provider error", error);
    })
  };

  return (
    <FormBox>
      {!userSuccess && (
        <Box mt={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography color="primary" variant="h5">
            Cadastrar usuário
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="center" >
            <form onSubmit={handleSubmitUser}>
              <Box mt={2}>
                <TextField name="name" label="Nome" variant="outlined" type="name" value={user.name} onChange={handleUserInputChange} fullWidth/>
              </Box>
              <Box mt={2}>
                <TextField name="email"  label="Email" variant="outlined" type="email" value={user.email} onChange={handleUserInputChange} fullWidth/>
              </Box>
              <Box mt={2}>
                <TextField name="password"  label="Senha" variant="outlined" type="password" value={user.password} onChange={handleUserInputChange} fullWidth/>
              </Box>
              <Box mt={4}>
                <Button variant="contained" type="submit">Cadastrar</Button>
              </Box>
            </form>
          </Box>
        </Box>
      )}
      {userSuccess && (
        <Box mt={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography color="primary" variant="h5">
            Cadastrar fornecedor
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="center" >
            <form onSubmit={handleSubmitProvider}>
              <Box mt={2}>
                <TextField name="name" label="Nome" variant="outlined" type="name" value={provider.name} onChange={handleProviderInputChange} fullWidth/>
              </Box>
              <Box mt={2}>
                <TextField name="cnpj" label="CNPJ" variant="outlined" type="text" value={provider.cnpj}  onChange={handleProviderInputChange} fullWidth/>
              </Box>
              <Box mt={2}>
                <TextField name="address" label="Endereço" variant="outlined" type="text" value={provider.address}  onChange={handleProviderInputChange} fullWidth/>
              </Box>
              <Box mt={2}>
                <TextField name="phone" label="Telefone" variant="outlined" type="text" value={provider.phone}  onChange={handleProviderInputChange} fullWidth/>
              </Box>
              <Box mt={2}>
                <TextField name="description" label="Descrição" variant="outlined" type="text" value={provider.description}  onChange={handleProviderInputChange} fullWidth/>
              </Box>
              <Box mt={4}>
                <Button variant="contained" type="submit">Cadastrar</Button>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </FormBox>
  );
};

export default NewProvider