import { Api } from './Api'

const ordersPathTo = {
  get: (id) => {
    return Api.request({
      url: '/orders/' + id + '.json',
      method: 'get',
    })
  },
  save: (data) => {
    return Api.request({
      url: '/orders.json',
      method: 'post',
      data: data
    })
  },
  update: (id, data) => {
    return Api.request({
      url: '/orders/' + id + '.json',
      method: 'put',
      data: data
    })
  },
  delete: (id) => {
    return Api.request({
      url: '/orders/' + id + '.json',
      method: 'delete',
    })
  },
  list: (params) => {
    return Api.request({
      url: '/orders.json',
      method: 'get',
      params
    })
  }
}

export default ordersPathTo