import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { providersPathTo } from '../utils/Api';
import SnackbarContext from '../contexts/SnackbarContext';
import TagList from './TagList';
import { Avatar, Box, Button, Divider, Typography } from '@mui/material';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';
import { apiBaseUrl } from '../utils/Api/Api';
import ProviderCard from './building_blocks/ProviderCard';
import { currency } from './building_blocks/Currency';
import { ShoppingCartProductsContext } from '../contexts/ShoppingCartProductsContext';


function ProviderShop() {
  const { id } = useParams();
  const [provider, setProvider] = useState();
  const { shoppingCartProducts, setShoppingCartProducts } = useContext(ShoppingCartProductsContext);
  const navigate = useNavigate();
  const { openSnackbar } = useContext(SnackbarContext);
  const handleButtonClick = (product, index) => {
    const newCartProduct = {
      ...product,
      cartQuantity: 1,
      productIndex: index
    }
    setShoppingCartProducts(
      [ ...shoppingCartProducts, newCartProduct])
    localStorage.setItem('shoppingCartProducts', JSON.stringify([ ...shoppingCartProducts, newCartProduct]))
    localStorage.setItem('shoppingProvider', JSON.stringify(provider))
  };

  const changeCartQuantity = (id, operation) => {
    let newShoppingCartProducts = [...shoppingCartProducts];
    const cartProduct = shoppingCartProducts.find(product => product.id === id);
    operation === '+' ? cartProduct.cartQuantity += 1 : cartProduct.cartQuantity -= 1
    if(cartProduct.cartQuantity < 1) {
      var index = newShoppingCartProducts.findIndex(item => item.id === id);
      newShoppingCartProducts.splice(index, 1);
    }
    setShoppingCartProducts(newShoppingCartProducts)
    localStorage.setItem('shoppingCartProducts', JSON.stringify(newShoppingCartProducts))
  }

  useEffect(() => {
    if (id) {
      providersPathTo.provider_with_products({id: id}).then(result => {
        setProvider(result.data);
      });
    }
  }, [])

  if (!provider) {
    return <div>Carregando...</div>;
  }

  return (
    <Box>
      {provider.business_banner && 
        <Box sx={{width: "100%", height: "140px"}}>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${apiBaseUrl}${provider.business_banner}`} />
        </Box>
      }
      <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <Box flexGrow={1} m={2} textAlign="left">
            <Typography variant="body1"><b>{provider.name}</b></Typography>
            <Typography fontSize={14} color="gray">
                <small>Pet Shop &#9679; 3,2km</small>
            </Typography>
            <Typography fontSize={14} color="gray">
                <small>27-37min &#9679; R$10,00</small>
            </Typography>
            <Typography color="primary" fontSize={14}><small>Perfil da loja</small></Typography>
        </Box>
        <Box mr={2}>
          <Avatar sx={{border: '1px solid lightgray', marginLeft: 'auto', width: '60px', height: '60px'}} src={provider.business_logo && 'http://localhost:3000' + provider.business_logo} />
        </Box>
      </Box>
      <Divider><Typography color="primary"><b>Produtos</b></Typography></Divider>
      {provider.products.map((product, index) => (
          <Box key={product.id}>
              <Box m={2} display="flex" sx={{width: "100%", height: '110px'}}>
                  <Box sx={{width: "60%"}}>
                      <Typography mr={2} textAlign="left" variant="body2"><b>{product.title}</b></Typography>
                      <Typography mb={1} mr={2} textAlign="justify" sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                          fontSize: '13px',
                      }} color="gray">
                          {product.description}
                      </Typography>
                      <Typography variant="body2" textAlign="left"><b>{currency(product.price)}</b></Typography>
                  </Box>
                  <Box mb="1" sx={{width: "30%", height: '110px', position: "relative"}}>
                      {!shoppingCartProducts.find(item => item.id === product.id) &&
                        <Button sx={{position: 'absolute', bottom: 0, right: 0, borderRadius: '8px', backgroundColor: 'white', minWidth: '35px', minHeight: '30px', "&.MuiButtonBase-root:hover": {
                        bgcolor: "white"}}} variant="outlined" onClick={() => handleButtonClick(product, index)}>+</Button>
                      }
                      {shoppingCartProducts.find(item => item.id === product.id) && 
                        <Box sx={{width: "100%", height: "25%", backgroundColor: 'white', position: 'absolute', bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <Box><Button onClick={() => changeCartQuantity(product.id, '-')}><Typography variant="h5" color="primary">-</Typography></Button></Box>
                          <Box><Typography variant="body2">{shoppingCartProducts.find(item => item.productIndex === index).cartQuantity}</Typography></Box>
                          <Box><Button onClick={() => changeCartQuantity(product.id, '+')}><Typography variant="h5" color="primary">+</Typography></Button></Box>
                        </Box>
                      }
                      <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={product.item_photo ? `${apiBaseUrl}${product.item_photo}` : ProductPhotoPlaceholder} />
                  </Box>
              </Box>
              {provider.products.length !== index + 1 && (
                  <Divider variant="middle"/>
              )}
          </Box>
      ))}
    </Box>
  );
}

export default ProviderShop

