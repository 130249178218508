import React, { useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { UserContext } from '../contexts/UserContext';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SnackbarContext from '../contexts/SnackbarContext';
import { IconButton, Typography, styled } from '@mui/material';
import FormBox from './building_blocks/FormBox';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const Profile = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { openSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    document.title = 'TudoPets - Perfil';
  }, []);
  
  const handleLogout = () => {
    localStorage.removeItem('current_user');
    setCurrentUser(null); // when set, ProtectedRoute will redirect to login
    openSnackbar('Logout efetuado com sucesso!');
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
  };

  const handleAvatarClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = handleImageChange;
    input.click();
  };

  if (!currentUser) {
    return null;
  }
      
  return (
    <Box>
      <FormBox>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography color="primary" variant="h5">
            Meu perfil
          </Typography>
          <Box mt={2}>
            <IconButton onClick={handleAvatarClick}>
              <Avatar alt={`${currentUser.name ? currentUser.name : currentUser.email}`} src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Box>
          <Box mt={2}>
            <Typography>
              Olá, {currentUser.name ? currentUser.name : currentUser.email}
            </Typography>
            <Typography variant="subtitle" color="primary">
              {currentUser.name ? currentUser.email : ''}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center" >
              <Box mt={2}>
              </Box>
              <Box mt={2}>
              </Box>
              <Box mt={4}>
              <Button variant="contained" type="button" onClick={() => handleLogout()}>Sair da conta</Button>
              </Box>
          </Box>
      </Box>
    </FormBox>
    </Box>
  );
};

export default Profile;