import React, { useContext, useEffect, useState } from "react";
import { providersPathTo } from "../utils/Api";
import { Box, Button, ButtonBase, Typography  } from "@mui/material";
import ProviderCard from "./building_blocks/ProviderCard";
import { UserContext } from "../contexts/UserContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";

const Home = () => {
    const [providers, setProviders] = useState([]);
    const navigate = useNavigate()
    const [defaultAddress, setDefaultAddress] = useState()

    useEffect(() => {
      providersPathTo.list().then(result => {
        setProviders(result.data);
      });
      const storedUser = JSON.parse(localStorage.getItem('current_user'));
      setDefaultAddress(storedUser.default_address)
    }, []);

    useEffect(() => {
      document.title = 'TudoPets - Home';
    }, []);

  return (
    <Box>
        <Box m={2}> 
          { !defaultAddress &&
          <Button variant="outlined" color="primary" 
            onClick={() => navigate(`/address`) }
            >
            Adicionar endereço de entrega
          </Button>
          }
          { defaultAddress &&
            <Box display="flex" justifyContent="center">
              <ButtonBase onClick={() => navigate(`/address`) }>
                <Typography fontSize={14}>
                    <b>{defaultAddress.logradouro}, {defaultAddress.numero}</b> 
                </Typography>
                <ExpandMoreIcon ml={1} color="primary" fontSize="small"/>
              </ButtonBase>
            </Box>
          }
        </Box>
        <Box m={2} display="flex">
            <Typography variant="body1" gutterBottom component="div"><b>Lojas</b></Typography>
        </Box>
        <Box>
            <Box>
                {providers.map((provider) => (
                   <ProviderCard key={provider.id} provider={provider} />
                 ))}
            </Box>
        </Box>
    </Box>
  );
};

export default Home