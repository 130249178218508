import { Api } from './Api'

const usersPathTo = {
  get: (options) => {
    return Api.request({
      url: '/users/' + options.id + '.json',
      method: 'get',
      params: options.params
    })
  },
  login: (data) => {
    return Api.request({
      url: '/users/sign_in.json',
      method: 'post',
      data: { user: data }
    })
  },
  signup: (data) => {
    return Api.request({
      url: '/users.json',
      method: 'post',
      data: { user: data }
    })
  },
  forgot_password: (options) => {
    return Api.request({
      url: '/users/password.json',
      method: 'post',
      data: {user: {email: options.email}}
    })
  },
  reset_password: (data) => {
    return Api.request({
      url: '/users/password.json',
      method: 'put',
      data: data
    })
  },
  confirm_email: (params) => {
    return Api.request({
      url: '/users/confirmation.json',
      method: 'get',
      params: params
    })
  }
}


export default usersPathTo