import React, { createContext, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const openSnackbar = (message, severity) => {
    if (message) {
      setMessage(message);
    }
    if (severity) {
      setSeverity(severity);
    }
    setShowSnackbar(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, openSnackbar, closeSnackbar }}>
      <Snackbar 
        open={showSnackbar} 
        autoHideDuration={6000} 
        onClose={closeSnackbar} 
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={severity} onClose={closeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;