import React, { useState, useContext, useEffect } from 'react';
import { usersPathTo } from '../utils/Api';
import { UserContext } from '../contexts/UserContext';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import SnackbarContext from '../contexts/SnackbarContext';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';
import TudoPetsLogo from '../images/tudopets_logo_laranja.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import FormBox from './building_blocks/FormBox';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/profile'); // maybe change to /home
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    document.title = 'TudoPets - Entrar';
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    usersPathTo.login({email: email, password: password}).then(result => {
      setCurrentUser(result.data);
      localStorage.setItem('current_user', JSON.stringify(result.data))
    }, error => {
      openSnackbar(error.response.data.error, "error");
      console.log("login error", error);
    })
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    usersPathTo.forgot_password({email: email}).then(result => {
      console.log("forgot_password", result);
    }, error => {})
    console.log('Email:', email);
  }

  return (
    <FormBox>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box display="flex" flexDirection="column"
        component="img"
        src={TudoPetsLogo}
        alt="Logo TudoPets"
        sx={{
          marginTop: '20px',
          width: '200px', 
          height: 'auto', 
        }}
        />
        {/* <Typography variant="h5">
          Login
        </Typography> */}
        <Box display="flex" flexDirection="row" justifyContent="center" >
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <TextField label="Email" name="email" variant="outlined" type="email" value={email} onChange={handleEmailChange} fullWidth/>
            </Box>
            <Box mt={2}>
              <TextField label="Senha" name="password" variant="outlined" type="password" value={password} onChange={handlePasswordChange} fullWidth/>
            </Box>
            <Box mt={4}>
              {/* <Button variant="contained" type="submit">Login</Button> */}
              {/* <RouterLink to="/register">
                <Button variant="outlined" type="button">Crie conta</Button>
              </RouterLink> */}
              <RouterLink to="/register">
                <Button variant="outlined" type="button">Criar conta</Button>
              </RouterLink>
              <Button variant="contained" type="submit">Login</Button>
              {/* <Button variant="contained" type="button" onClick={handleForgotPassword}>Esqueci a senha</Button> */}
            </Box>
            <Box mt={2}>
              <Typography color="primary" variant="body2" >
                <Link onClick={handleForgotPassword} underline="hover">Esqueceu sua senha?</Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>
    </FormBox>
  );
};

export default Login;