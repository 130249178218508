import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Card, CardActionArea, CardContent, Divider, Typography  } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ShoppingCartProductsContext } from '../../contexts/ShoppingCartProductsContext';
import { useContext } from 'react';
import { currency } from '../../components/building_blocks/Currency';


const Content = styled(CardContent)({
    display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '8px'
});

const ShoppingCartElement = () => {
    const { totalAmount } = useContext(ShoppingCartProductsContext);
    const { totalQuantity } = useContext(ShoppingCartProductsContext);

    const navigate = useNavigate()

    if (totalAmount && window.location.href.indexOf("shopping-cart") === -1) {
        return (
            <Box sx={{marginTop: '80px'}} display="flex">
                <Box display="flex" alignItems="flex-start" sx={{backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', height: '54px', position: 'fixed', bottom: '56px', zIndex: '9999', width: '100%'}}>
                    <Box sx={{marginTop: '8px', marginLeft: '8px' }}>
                        <Typography textAlign="left" variant="body2" sx={{fontSize: '13px'}}>
                            <b>Total</b>
                        </Typography>
                        <Typography textAlign="left" sx={{fontSize: '13px' }} color="gray">
                            {currency(totalAmount)} / {totalQuantity} itens
                        </Typography>
                    </Box>
                    <Box sx={{marginLeft: 'auto', marginRight: '12px', alignSelf: 'center'}}>
                        <Button variant="contained" type="button" onClick={() => navigate('/shopping-cart')}>Ver carrinho</Button>
                    </Box>
                </Box>
            </Box>
        );
    }
};

export default ShoppingCartElement;