// TagsContext.js
import React, { createContext, useState } from 'react';

const TagsContext = createContext();

const TagsProvider = ({ children }) => {
  const [tags, setTags] = useState([]);

  const addTag = (tag) => {
    setTags([...tags, tag]);
  };

  const updateTag = (tag) => {
    setTags(tags.map((t) => (t.id === tag.id ? tag : t)));
  };

  const removeTag = (id) => {
    setTags(tags.filter((t) => t.id !== id));
  };

  return (
    <TagsContext.Provider value={{ tags, setTags, updateTag, addTag, removeTag }}>
      {children}
    </TagsContext.Provider>
  );
};

export { TagsContext, TagsProvider };