import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)({
    backgroundColor: 'white',
    padding: '24px',
    border: '1px solid #80808030',
    borderRadius: '8px',
    margin: '20px',
});

const FormBox = ({ children }) => {
    return <StyledBox>{children}</StyledBox>;
};

export default FormBox;