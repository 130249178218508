import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ordersPathTo } from '../utils/Api';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, ButtonBase, Divider, Typography } from '@mui/material';
import SnackbarContext from '../contexts/SnackbarContext';
import FormBox from './building_blocks/FormBox';
import { currency } from './building_blocks/Currency';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';
import { apiBaseUrl } from '../utils/Api/Api';
import visa from '../images/visa_card.png';
import master from '../images/master_card.png';
import LocationIcon from '../images/location.png';



const OrderDetails = () => {
  const { currentUser } = useContext(UserContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState({});

  useEffect(() => {
    ordersPathTo.get(id).then(result => {
      setOrder(result.data)
      document.title = `TudoPets - Pedido #${result.data.id}`;
    })
  }, [])

  if (order.provider) {
    return (
      <Box>
        <FormBox display="flex" flexDirection="column">
          <Box>
            <Typography color="primary" variant="h5">
              <b>Resumo do pedido</b>
            </Typography>
            <Box m={2}>
              <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '8px'}}>
                <Box>
                  <Avatar sx={{border: '1px solid lightgray'}} src={order.provider_business_logo && 'http://localhost:3000' + order.provider_business_logo} />
                </Box>
                <Box flexGrow={1} ml={2} textAlign="left">
                  <Typography variant="body1"><b>{order.provider.name}</b></Typography>
                  <Typography fontSize={14} color="gray">
                    <small>Pedido #{order.id} &#9679; {order.date}</small>
                  </Typography>
                  <Typography fontSize={14} color="primary">
                    <ButtonBase onClick={() => navigate(`/provider/${order.provider.id}`) }>
                      <small>Ver loja</small>
                    </ButtonBase>
                  </Typography>
                </Box>
              </Box>
             <Box>
              <Divider><Typography color="primary"><b>Produtos</b></Typography></Divider>
              {order.order_items.map((order_item, index) => (
                <Box key={order_item.id}>
                  <Box m={2} display="flex" sx={{width: "100%", height: '110px'}}>
                    <img style={{ width: '80px', height: '120px', marginRight: '25px', objectFit: 'cover' }} src={order_item.item_photo ? `${apiBaseUrl}${order_item.item_photo}` : ProductPhotoPlaceholder} />
                    <Box sx={{width: "80%"}}>
                      <Typography mr={2} textAlign="left" variant="body2"><b>{order_item.title}</b></Typography>
                      <Typography variant="body2" textAlign="left">{currency(order_item.price * order_item.quantity)}<small> ({order_item.quantity}x)</small></Typography>
                    </Box>
                  </Box>
                  {order.order_items.length !== index + 1 && (
                    <Divider sx={{marginTop: '30px'}} variant="middle"/>
                  )}
                </Box>
              ))}
            </Box>
            <Box mt={6}>
              <Divider><Typography color="primary"><b>Resumo de valores</b></Typography></Divider>
              <Typography mt={1} mb={2} textAlign="left" variant="body2"><b>Subtotal: </b>{currency(order.total_amount / 100)}</Typography>
              <Typography mt={1} mb={2} textAlign="left" variant="body2"><b>Taxa de entrega: </b>{currency(10)}</Typography>
              <Typography mt={1} mb={2} textAlign="left" variant="body2"><b>Total: </b>{currency(10 + parseFloat(order.total_amount) / 100)}</Typography>
            </Box>
            <Box mt={6}>
              <Divider><Typography color="primary"><b>Pago pelo app</b></Typography></Divider>
              <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '15px'}}>
                <Box>
                  {order.payment_method.brand === 'visa' ? <img src={visa} style={{width: '40px'}}/> : <img src={master} style={{width: '40px'}}/>}
                </Box>
                <Box flexGrow={1} ml={2} textAlign="left">
                  <Typography sx={{fontSize: '14px', textTransform: 'capitalize'}}>{order.payment_method.brand} &#9679; Crédito</Typography>
                  <Typography sx={{fontSize: '14px'}} color="gray">**** {order.payment_method.last4}</Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={6}>
              <Divider><Typography color="primary"><b>Endereço de entrega</b></Typography></Divider>
              <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <img style={{ width: '20px', height: '20px', marginRight: '12px' }} src={LocationIcon} />
                <Typography sx={{marginTop: '16px'}} mb={2} textAlign="left" variant="body2">{order.delivery_address}</Typography>
              </Box>
            </Box>
            </Box>
          </Box>
        </FormBox>
      </Box>
    );
  }

  // return (
  //   <div>
  //     <Box mt={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  //       <h2>Detalhes do pedido</h2>
  //       <p>{order.id}</p>
  //       <p>Total: R$ {order.total_amount}</p>
  //       {order.status === 'paid' ? <p>Status: Em entrega</p> : ''}
  //       {order.status === 'pending' ? <p>Status: Pendente</p> : ''}
  //       {order.status === 'failed' ? <p>Status: Pagamento falhou</p> : ''}
  //       {order.status === 'shipped' ? <p>Status: Entregue</p> : ''}
  //       {order.status === 'canceled' ? <p>Status: Cancelado</p> : ''}
  //       {order.status === 'done' ? <p>Status: Finalizado</p> : ''}
  //     </Box>
  //   </div>
  // );
}

export default OrderDetails