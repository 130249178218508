import { Api } from './Api'

const productsPathTo = {
  get: (id) => {
    return Api.request({
      url: '/products/' + id + '.json',
      method: 'get',
    })
  },
  save: (data, providerTags = []) => {
    let payload = { product: data };
    if (providerTags.length > 0) {
      payload['provider_tags'] = providerTags;
    }
    return Api.request({
      url: '/products.json',
      method: 'post',
      data: payload
    })
  },
  update: (id, data, providerTags = []) => {
    let payload = { product: data };
    if (providerTags.length > 0) {
      payload['provider_tags'] = providerTags;
    }
    return Api.request({
      url: '/products/' + id + '.json',
      method: 'put',
      data: payload
    })
  },
  delete: (id) => {
    return Api.request({
      url: '/products/' + id + '.json',
      method: 'delete',
    })
  },
  list: (params) => {
    return Api.request({
      url: '/products.json',
      method: 'get',
      params
    })
  }

}

export default productsPathTo