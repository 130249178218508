import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, Card, CardActionArea, CardContent, Divider, Typography  } from "@mui/material";
import FormBox from './building_blocks/FormBox';
import { currency } from './building_blocks/Currency';
import { apiBaseUrl } from '../utils/Api/Api';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import cardsPathTo from '../utils/Api/cardsPathTo';
import ordersPathTo from '../utils/Api/ordersPathTo';
import visa from '../images/visa_card.png';
import master from '../images/master_card.png';
import SnackbarContext from '../contexts/SnackbarContext';
import { ShoppingCartProductsContext } from '../contexts/ShoppingCartProductsContext';

const ShoppingCartCheckout = () => {
  const provider = JSON.parse(localStorage.getItem('shoppingProvider'));
  const checkoutProducts = JSON.parse(localStorage.getItem('shoppingCartProducts'));
  const totalAmount = localStorage.getItem('totalAmount');
  const navigate = useNavigate()
  const totalQuantity = localStorage.getItem('totalQuantity');
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  const { openSnackbar } = useContext(SnackbarContext);
  const { setTotalQuantity } = useContext(ShoppingCartProductsContext);
  const { setTotalAmount } = useContext(ShoppingCartProductsContext);
  const { setShoppingCartProducts } = useContext(ShoppingCartProductsContext);

  let selectedPaymentIndex = 0
  localStorage.getItem('payment_method') != null ? selectedPaymentIndex = localStorage.getItem('payment_method') : selectedPaymentIndex = 0

  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    cardsPathTo.list().then(result => {
      setPaymentMethods(result.data);
    });
    document.title = 'TudoPets - Carrinho de compras';
  }, [])

  const handleCheckout = () => {
    ordersPathTo.save({
      provider_id: provider.id,
      total_amount: totalAmount,
      total_quantity: totalQuantity,
      payment_method_id: paymentMethods[selectedPaymentIndex].id,
      products: checkoutProducts,
      delivery_address: currentUser.default_address.logradouro + ", " + currentUser.default_address.numero
    }).then((result) => {
      navigate('/orders/' + result.data.id)
    })
  }

  const clearShoppingCart = () => {
    localStorage.removeItem('shoppingCartProducts');
    localStorage.removeItem('totalAmount');
    localStorage.removeItem('totalQuantity');
    setTotalAmount(0)
    setTotalQuantity(0)
    setShoppingCartProducts([])
    openSnackbar("Carrinho limpado com sucesso!", "success");
    navigate('/home')
  }

  return (
    <Box>
      <FormBox display="flex" flexDirection="column">
        <Box>
          <Typography color="primary" variant="h5">
            <b>Carrinho de compras</b>
          </Typography>
          <Box m={2}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '8px'}}>
              <Box>
                <Avatar sx={{border: '1px solid lightgray'}} src={provider.business_logo && 'http://localhost:3000' + provider.business_logo} />
              </Box>
              <Box flexGrow={1} ml={2} textAlign="left">
                <Typography variant="body1"><b>{provider.name}</b></Typography>
                <Typography fontSize={14} color="primary">
                  <ButtonBase onClick={() => navigate(`/provider/${provider.id}`) }>
                    <small>Adicionar outros itens</small>
                  </ButtonBase>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Divider><Typography color="primary"><b>Produtos adicionados</b></Typography></Divider>
            {checkoutProducts.map((product, index) => (
              <Box key={product.id}>
                <Box m={2} display="flex" sx={{width: "100%", height: '110px'}}>
                  <img style={{ width: '80px', height: '120px', marginRight: '25px', objectFit: 'cover' }} src={product.item_photo ? `${apiBaseUrl}${product.item_photo}` : ProductPhotoPlaceholder} />
                  <Box sx={{width: "60%"}}>
                    <Typography mr={2} textAlign="left" variant="body2"><b>{product.title}</b></Typography>
                    <Typography mb={1} mr={2} textAlign="justify" sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical',
                      fontSize: '13px',
                    }} color="gray">
                      {product.description}
                    </Typography>
                    <Typography variant="body2" textAlign="left"><b>{currency(product.price * product.cartQuantity)}</b> <small>({product.cartQuantity}x)</small></Typography>
                  </Box>
                </Box>
                {checkoutProducts.length !== index + 1 && (
                  <Divider sx={{marginTop: '30px'}} variant="middle"/>
                )}
              </Box>
            ))}
          </Box>
          <Box mt={6}>
            <Divider><Typography color="primary"><b>Resumo de valores</b></Typography></Divider>
            <Typography mt={1} textAlign="left" variant="body2"><b>Quantidade de itens: </b>{totalQuantity}</Typography>
            <Typography mt={1} mb={2} textAlign="left" variant="body2"><b>Valor total: </b>{currency(totalAmount)}</Typography>
          </Box>
          <Box mt={1}>
            <Divider><Typography color="primary"><b>Endereço de entrega</b></Typography></Divider>
            { currentUser.default_address &&
              <Box>
                <Typography mt={1} textAlign="left" variant="body2">{currentUser.default_address.cep}</Typography>
                <Typography mt={1} textAlign="left" variant="body2">{currentUser.default_address.logradouro}, {currentUser.default_address.numero}</Typography>
                <Typography mt={1} textAlign="left" variant="body2">{currentUser.default_address.complemento}</Typography>
                <Typography mt={1} mb={2} textAlign="left" variant="body2">{currentUser.default_address.bairro}</Typography>
              </Box>
            }
            { !currentUser.default_address &&
              <Box mt={2} mb={2}>
                <Button variant="outlined" color="primary" 
                onClick={() => navigate(`/address?from=checkout`) }>
                  Adicionar endereço de entrega
                </Button>
              </Box>
            }
          </Box>
          <Box mt={1}>
            <Divider><Typography color="primary"><b>Forma de pagamento</b></Typography></Divider>
            { paymentMethods && paymentMethods.length == 0 &&
              <ButtonBase onClick={() => navigate(`/new-card/`) }>
                <Typography mt={2} mb={2} textAlign="left" color="primary" variant="body2">Adicionar cartão de crédito</Typography>
              </ButtonBase>
            }
            { paymentMethods && paymentMethods.length > 0 &&
              <Box mt={2} mb={8} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <Box>
                  {paymentMethods[selectedPaymentIndex].card.brand === 'visa' ? <img src={visa} style={{width: '40px'}}/> : <img src={master} style={{width: '40px'}}/>}
                </Box>
                <Box flexGrow={1} ml={2} textAlign="left">
                  <Typography sx={{fontSize: '14px', textTransform: 'capitalize'}}>{paymentMethods[selectedPaymentIndex].card.brand} &#9679; Crédito</Typography>
                  <Typography sx={{fontSize: '14px'}} color="gray">**** {paymentMethods[selectedPaymentIndex].card.last4}</Typography>
                </Box>
                <Box flexGrow={1} ml={2} textAlign="right">
                  <ButtonBase onClick={() => navigate(`/payment-methods?switch`) }>
                    <Typography fontSize={14} color="orange">Trocar</Typography>
                  </ButtonBase>
                </Box>
              </Box>
            }
          </Box>
          <Box>
            <Button onClick={() => clearShoppingCart()} variant="outlined" type="button">Limpar carrinho</Button>

            <Button sx={{marginLeft: 'auto'}} disabled={!currentUser.default_address} variant="contained" type="button" onClick={() => handleCheckout()}>Finalizar compra</Button>
          </Box>
        </Box>
      </FormBox>
    </Box>
  );
}

export default ShoppingCartCheckout