import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Card, CardActionArea, CardContent, Typography  } from "@mui/material";
import StarIcon from '../../images/star_rating.png';
import { useNavigate } from 'react-router-dom';

const Content = styled(CardContent)({
    display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '8px'
});

const ProviderCard = (props) => {
    const { provider } = props;
    const navigate = useNavigate()

    return (
        <Box m={2}>
            <Card>
                <CardActionArea onClick={() => navigate('/provider/' + provider.id)}>
                    <Content sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '8px'}}>
                        <Box>
                            <Avatar sx={{border: '1px solid lightgray'}} src={provider.business_logo && 'http://localhost:3000' + provider.business_logo} />
                        </Box>
                        <Box flexGrow={1} ml={2} textAlign="left">
                            <Typography variant="body1"><b>{provider.name}</b></Typography>
                            <Typography fontSize={14} color="gray">
                                <small><img style={{ width: '10px', height: '10px' }} src={StarIcon} /> 5,0 &#9679; Pet Shop &#9679; 3,2km</small>
                            </Typography>
                            <Typography fontSize={14} color="gray">
                                <small>27-37min &#9679; R$10,00</small>
                            </Typography>
                        </Box>
                    </Content>
                </CardActionArea>
            </Card>
        </Box>
    );
};

export default ProviderCard;