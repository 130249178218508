import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { productsPathTo } from '../utils/Api';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { ProviderProductsContext } from '../contexts/ProviderProductsContext';
import { Box, Button, Switch, TextField, Typography } from '@mui/material';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';
import FormBox from './building_blocks/FormBox';
import SnackbarContext from '../contexts/SnackbarContext';
import TagsEdit from './TagsEdit';
import { apiBaseUrl } from '../utils/Api/Api';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { CheckBox } from '@mui/icons-material';

const ProviderProduct = () => {
  const { currentUser } = useContext(UserContext);
  const { addProviderProduct, updateProviderProduct, removeProviderProduct, getTags } = useContext(ProviderProductsContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [photoPreview, setPhotoPreview] = useState();
  const [product, setProduct] = useState({
    id: '',
    title: '',
    description: '',
    price: '',
    published: false,
    quantity: '',
    provider_id: currentUser.provider_id,
    item_photo: '',
    tags: [],
  });

  useEffect(() => {
    if (id) {
      productsPathTo.get(id).then(result => {
        setProduct(result.data);
      });
    }
  }, [id]);

  useEffect(() => {
    document.title = 'TudoPets - Produto do fornecedor';
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setProduct({ ...product, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (id) {
      productsPathTo.update(id, product, getTags(product.tags)).then(result => {
        // console.log(product)
        updateProviderProduct(result.data);
        navigate(`/provider-dashboard`);
        openSnackbar("Produto atualizado com sucesso!", "success");
      });
    } else {
      productsPathTo.save(product, getTags(product.tags)).then(result => {
        addProviderProduct(result.data);
        navigate(`/provider-dashboard`);
        openSnackbar("Produto criado com sucesso!", "success");
      });
    }
  };

  const handleDelete = () => {
    console.log("handleDelete", product);
    if (window.confirm('Você tem certeza que deseja apagar este produto?')) {
      productsPathTo.delete(product.id).then(() => {
        removeProviderProduct(product.id);
        openSnackbar("Produto removido com sucesso!", "success");
        navigate(`/provider-dashboard`);
      })
    };
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onload = () => {
      setProduct({...product, ['item_photo']: reader.result});
      setPhotoPreview(URL.createObjectURL(event.target.files[0]));
    }
    
    reader.readAsDataURL(file);
    
  }

  return (
    <FormBox>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="baseline">
        <Typography color="primary" variant="h5" sx={{alignSelf: 'center'}}>
          {id ? 'Editar produto' : 'Novo produto'}
        </Typography>
        <Box style={{width: '100%'}} >
          <form onSubmit={handleSubmit}>
            <Typography mt={2} mb={3} textAlign="left" variant="body2"><b>Dados principais</b></Typography>
            <Box>
              <Box mt={2}>
                <TextField
                  name="title"
                  label="Título"
                  variant="outlined"
                  type="text"
                  value={product.title}
                  onChange={handleInputChange}
                  multiline
                  rowsMax={4}
                  minRows={2}
                  maxRows={4}
                  fullWidth
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                    },
                  }}
                  inputProps={{
                    disabled: product.been_published === true,
                  }}
                />
                {product.been_published === true && (<Typography variant="body2">Este produto foi publicado na loja. Título não pode ser alterado.</Typography>)}
              </Box>
              <Box mt={2}>
                <TextField name="description"  label="Descrição" variant="outlined" type="text" value={product.description} multiline rowsMax={4} minRows={2} maxRows={4} onChange={handleInputChange} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TextField name="price"  label="Preço" variant="outlined" type="number" step="0.01" value={product.price} onChange={handleInputChange} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TextField name="quantity" label="Quantidade" variant="outlined" type="number" value={product.quantity} inputProps={{ min: 0 }} onChange={handleInputChange} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TagsEdit resource={product} setResource={setProduct} />
              </Box>
              <Box mt={2}>
            </Box>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleImageChange}
              />
            <Typography mt={2} mb={3} textAlign="left" variant="body2"><b>Foto do produto</b></Typography> 
            <Box>
              <label htmlFor="raised-button-file">
                <Button variant="outlined" component="span">
                {product.item_photo ? 'Atualizar' : 'Adicionar'} foto
                </Button>
              </label>
              { photoPreview && (
                  <img style={{ width: '100%', height: '150px', objectFit: 'scale-down', marginTop: '12px' }} src={photoPreview} />
                )
              } 
              { !photoPreview && (
                <img style={{ width: '100%', height: '150px', objectFit: 'scale-down', marginTop: '12px' }} src={id && product.item_photo ? `${apiBaseUrl}${product.item_photo}` : ProductPhotoPlaceholder} />
              )}
            </Box>
            {/* <Box mt={2}>
              <Typography mt={2} mb={2} textAlign="left" variant="body2"><b>Deseja publicar o produto em sua loja?</b></Typography>
              <Switch
                name="published"
                checked={product.published}
                onChange={handleInputChange}
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box> */}
            <Box display="flex" mt={4}>
              {id && (
                <Button variant="contained" color="error" onClick={handleDelete}>Apagar</Button>
              )}
              <Button sx={{marginLeft: 'auto'}} variant="contained" type="submit">{id ? 'Atualizar' : 'Criar'}</Button>
            </Box>
          </Box>
          </form>
        </Box>
      </Box>
    </FormBox>
  );
};

export default ProviderProduct

