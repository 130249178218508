import React, { useContext } from 'react';
import { TagsContext } from '../contexts/TagsContext';
import SnackbarContext from '../contexts/SnackbarContext';
import { tagsPathTo } from '../utils/Api'; // só será util quando puder add novas tags
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


const filter = createFilterOptions();
const TagsEdit = (props) => {
  const { tags, addTag } = useContext(TagsContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    name: '',
  });

  const handleClose = () => {
    setDialogValue({
      name: '',
    });
    toggleOpen(false);
  };

  const handleDialogSubmit = (event) => {
    event.preventDefault();
    tagsPathTo.save({
      name: dialogValue.name,
    }).then((result) => {
      openSnackbar("Tag adicionada com sucesso!", "success");
      addTag(result.data);
      props.setResource((prevState) => ({
        ...prevState,
        tags: [...prevState.tags, result.data]
      }));
    });

    handleClose();
  };


  return (
    <div>
      <Autocomplete
        multiple
        options={tags}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          const newTag = newValue.find((tag) => tag.inputValue !== undefined);

          if (newTag) {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({ name: newTag.inputValue });
            });
          } else {
            props.setResource((prevState) => ({
              ...prevState,
              tags: newValue
            }));
          }
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={props.resource.tags}
        filterSelectedOptions
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (filtered.length === 0 && params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Adicionar "${params.inputValue}"`,
            });
          }
          return filtered
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            placeholder=""
          />
        )}
      />

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleDialogSubmit}>
          <DialogTitle>Adicionar nova tag</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  name: event.target.value,
                })
              }
              label="Nome"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="button" onClick={handleDialogSubmit} variant="contained">Adicionar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
  
};

export default TagsEdit