import React, { createContext, useState } from 'react';

const ProviderProductsContext = createContext();

const ProviderProductsProvider = ({ children }) => {
  const [providerProductList, setProviderProductList] = useState([]);

  const addProviderProduct = (product) => {
    setProviderProductList([...providerProductList, product]);
  };

  const updateProviderProduct = (product) => {
    setProviderProductList(providerProductList.map((p) => (p.id === product.id ? product : p)));
  };

  const updateProviderProductList = (newProviderProductList) => {
    setProviderProductList(newProviderProductList);
  };

  const removeProviderProduct = (id) => {
    setProviderProductList(providerProductList.filter((p) => p.id !== id));
  };

  const getTags = (tags=[]) => {
    return providerProductList.reduce((acc, product) => {
      product.tags.forEach((tag) => {
        if (!acc.some((t) => t.id === tag.id)) {
          acc.push(tag);
        }
      });
      return acc;
    }, [...tags]);
  };

  return (
    <ProviderProductsContext.Provider value={{ providerProductList, addProviderProduct, updateProviderProductList, updateProviderProduct, removeProviderProduct, getTags }}>
      {children}
    </ProviderProductsContext.Provider>
  );
};

export { ProviderProductsContext, ProviderProductsProvider };