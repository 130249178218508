import './App.css';
import '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Login, Navbar, Register, PasswordEdit, 
  EmailConfirmation, FrontPage, Profile, 
  NewProvider, NewCard, PaymentMethods, ProviderDashboard, ProviderProduct, 
  ProviderDetails, Home, ProtectedRoute, ProviderShop,
  AddressForm, ShoppingCartCheckout, OrderDetails } from './components';
import { UserContext } from './contexts/UserContext';
import { SnackbarProvider } from "./contexts/SnackbarContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useContext, useEffect } from 'react';
import { TagsContext } from './contexts/TagsContext';
import { tagsPathTo } from './utils/Api';
import { ShoppingCartProductsProvider } from './contexts/ShoppingCartProductsContext';
import ShoppingCartElement from './components/building_blocks/ShoppingCartElement';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

function App() {
  const theme = createTheme({
    typography: {
     },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            margin: 0;
          }
          .form-box {
            background-color: 'white';
            padding: '24px';
            border: '1px solid #80808030';
            border-radius: '8px';
            margin: '20px';
          }
        `
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#f59723',
            textDecoration: 'none',
            '&:hover': {
              color: '#f59723',
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          },
        },
      },
      MuiButton: {
        variants: [ 
          {
            props: { variant: 'outlined' },
            style: {
            color: '#f59723',
            // backgroundColor: 'red',
            // '&:hover': {
            //   backgroundColor: 'darkred', 
            // },
          }
        }
        ],
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: 'white',
            margin: '4px',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#f59723',
      }
    },
  });

  const { currentUser } = useContext(UserContext);
  const { tags, setTags } = useContext(TagsContext);
  //esconder key
  const stripePromise = loadStripe('pk_test_51I6PYnHj1UkrSfve8SN2ABn7G98QGSXyOCbK1clkWWJadfvNOUSwa2EFlOq8qI8zAfvDrLseZoSWHq6Sr53OI2F4002aZJBqY8');

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await tagsPathTo.list();
        setTags(response.data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    if (currentUser) {
      fetchTags();
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <ShoppingCartProductsProvider>
          <Elements stripe={stripePromise}>
            <div className="App">
              <Router>
                <Routes>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/new-provider" element={<NewProvider />} />
                    <Route path="/provider-dashboard" element={<ProviderDashboard />} />
                    <Route path="/provider/products/new" element={<ProviderProduct />} />
                    <Route path="/provider/products/:id" element={<ProviderProduct />} />
                    <Route path="/provider/:id/details" element={<ProviderDetails />} />
                    <Route path="/new-card" element={<NewCard />} />
                    <Route path="/payment-methods" element={<PaymentMethods />} />
                    <Route path="/orders/:id" element={<OrderDetails />} />
                  </Route>
                  
                  <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/users/password/edit" element={<PasswordEdit />} />
                  <Route path="/confirmation" element={<EmailConfirmation />} />
                  <Route path="/inicio" element={<FrontPage />} />
                  <Route path="/provider/:id" element={<ProviderShop />} />
                  <Route path="/shopping-cart" element={<ShoppingCartCheckout />} />
                  <Route path="/address" element={<AddressForm />} />
                  <Route path="*" element={<FrontPage />} />
                </Routes>
                <ShoppingCartElement />
                <Navbar />
              </Router>
            </div>
          </Elements>
        </ShoppingCartProductsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
