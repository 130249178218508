import { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { usersPathTo } from "../utils/Api";
import SnackbarContext from "../contexts/SnackbarContext";

function EmailConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { openSnackbar } = useContext(SnackbarContext);

  // todo: const searchParams = useSearchParams(); implementar depois usando isso

  useEffect(() => {
    // Get the confirmation_token from the URL
    const searchParams = new URLSearchParams(location.search);
    const confirmationToken = searchParams.get("confirmation_token");

    // Remove the confirmation_token from the URL
    // navigate.replace({ search: "" });

    // Send a request to the API to confirm the email
    usersPathTo.confirm_email({ confirmation_token: confirmationToken }).then(result => {
      openSnackbar("Email confirmado com sucesso! Pode efetuar seu login", "success");
      navigate("/login");
    }, error => {
      console.error("Email confirmation failed:", error);
    })
  }, [navigate, location, openSnackbar]);

  return null;
}

export default EmailConfirmation;