import axios from 'axios';

export const apiBaseUrl = (window.location.href.indexOf('localhost') > -1) ? 'http://localhost:3000/' : 'https://api.tudopets.app/';

export const Api = axios.create({
  baseURL: apiBaseUrl,
  format: 'json'
});

Api.interceptors.request.use(async config => {
  var authentication_token = ""
  var currentUser = JSON.parse(localStorage.getItem('current_user'))

  if(currentUser){
    authentication_token = currentUser.authentication_token;
  }
  if (authentication_token) {
    config.headers['X-User-Token'] = authentication_token
    config.headers['X-User-Email'] = currentUser.email
  }
  return config;
});

Api.interceptors.response.use(
  response => response,
  error => {
    try {
      if (error.message) {
        console.log('Error:', error.message);
      }
    } catch (e) {
      console.error(e);
    }
    return Promise.reject(error);
  }
);
