import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { providersPathTo } from '../utils/Api';
import SnackbarContext from '../contexts/SnackbarContext';
import TagList from './TagList';
import { Box, Button, TextField, Typography } from '@mui/material';
import FormBox from './building_blocks/FormBox';
import { apiBaseUrl } from '../utils/Api/Api';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';

function ProviderDetails() {
  const { currentUser } = useContext(UserContext);
  const [provider, setProvider] = useState();
  const navigate = useNavigate();
  const { openSnackbar } = useContext(SnackbarContext);
  const [photoPreview, setPhotoPreview] = useState();

  // if (provider) {
  //   console.log(provider.name, provider.tags);
  // }
  
  useEffect(() => {
    if (currentUser.provider_id) {
      providersPathTo.get({id: currentUser.provider_id}).then(result => {
        setProvider(result.data);
      });
    }
  }, [currentUser.provider_id]);

  if (!provider) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    const { name, value} = e.target;
    setProvider({ ...provider, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    providersPathTo.update(provider.id, provider ).then(result => {
      openSnackbar("Fornecedor atualizado com sucesso!", "success");
      navigate('/provider-dashboard');
      // todo: atualizar contexto de provider com o resultado desse update para cachear dado.

      // console.log(result);
    })
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onload = () => {
      setProvider({...provider, ['business_banner']: reader.result});
      setPhotoPreview(URL.createObjectURL(event.target.files[0]));
    }
    
    reader.readAsDataURL(file);
    
  }

  return (
    <FormBox>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="baseline">
        <Typography color="primary" variant="h5" sx={{alignSelf: 'center'}}>
          Editar fornecedor
        </Typography>
        <Box style={{width: '100%'}} >
          <form onSubmit={handleSubmit}>
            <Typography mt={2} mb={3} textAlign="left" variant="body2"><b>Dados principais</b></Typography>
            <Box>
              <Box mt={2}>
                <TextField
                  name="title"
                  label="Título"
                  variant="outlined"
                  type="text"
                  value={provider.name}
                  onChange={handleChange}
                  disabled
                  fullWidth
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: '14px',
                    },
                  }}
                />
              </Box>
              <Box mt={2}>
                <TextField name="description"  label="Descrição" variant="outlined" type="text" value={provider.description} multiline rowsMax={4} minRows={2} maxRows={4} onChange={handleChange} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TextField name="cnpj"  label="CNPJ" variant="outlined" type="text" value={provider.cnpj} onChange={handleChange} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TextField name="phone" label="Telefone" variant="outlined" type="text" value={provider.phone} onChange={handleChange} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Typography mt={2} mb={3} textAlign="left" variant="body2"><b>Banner da loja</b></Typography>
              <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleImageChange}
              />
            <Box>
              <label htmlFor="raised-button-file">
                <Button variant="outlined" component="span">
                {provider.business_banner ? 'Atualizar' : 'Adicionar'} foto
                </Button>
              </label>
              { photoPreview && (
                  <img style={{ width: '100%', height: '150px', objectFit: 'scale-down', marginTop: '12px' }} src={photoPreview} />
                )
              } 
              { !photoPreview && (
                <img style={{ width: '100%', height: '150px', objectFit: 'scale-down', marginTop: '12px' }} src={provider.id && provider.business_banner ? `${apiBaseUrl}${provider.business_banner}` : ProductPhotoPlaceholder} />
              )}
            </Box>
            <Typography mt={2} mb={3} textAlign="left" variant="body2"><b>Endereço</b></Typography>
            <Box>
              <Box mt={2}>
                <TextField name="cep" label="Cep" disabled variant="outlined" type="text" value={provider.address.cep} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2} display="flex" textAlign="left">
                <Box sx={{width: '55%'}}>
                    <TextField name="localidade" label="Cidade" disabled variant="outlined" type="text" value={provider.address.localidade} disabled InputProps={{readOnly: true}} sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
                <Box sx={{width: '40%', marginLeft: '16px'}}>
                    <TextField name="uf" label="Estado" disabled variant="outlined" type="text" value={provider.address.uf} disabled InputProps={{readOnly: true}} fullWidth sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
              </Box>
              <Box mt={2}>
                <TextField name="logradouro" label="Rua" disabled variant="outlined" type="text" value={provider.address.logradouro} disabled InputProps={{readOnly: true}} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TextField name="bairro" label="Bairro" disabled variant="outlined" type="text" value={provider.address.bairro} disabled InputProps={{readOnly: true}} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2} display="flex" textAlign="left">
                <Box sx={{width: '40%'}}>
                    <TextField name="numero" label="Numero" disabled variant="outlined" type="text" value={provider.address.numero} fullWidth sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
                <Box sx={{width: '55%',  marginLeft: '16px'}}>
                    <TextField name="complemento" label="Complemento" disabled variant="outlined" type="text" value={provider.address.complemento} sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
              </Box>
            </Box>
            <Box display="flex" mt={4}>
              <Button sx={{marginLeft: 'auto'}} variant="contained" type="submit">Atualizar</Button>
            </Box>
          </Box>
          </form>
        </Box>
      </Box>
    </FormBox>
  );
}

export default ProviderDetails

