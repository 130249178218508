// "Seja bem-vindo(a) ao TudoPets.app!" "O tudopets é um aplicativo gray label que visa conectar pessoas da industria animal local"
//       "O tudopets é um aplicativo de produtos e serviços que ajuda pessoas a encontrar o que precisar disponibilizados no comercio local de sua cidade".
//       "O tudopets visa aumentar o conforto de seus clientes e fornecedores para encontrar produtos ou serviços de empresas que fornecerem."
//       "O tudopets será um aplicativo de boa UI e UX. Com uma boa qualidade de desenvolvimento."

import React, { useState, useContext } from 'react';
import { leadsPathTo } from '../utils/Api';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Typography, CardContent, TextField, Box, CardActions, Paper, InputAdornment } from '@mui/material';
import SnackbarContext from '../contexts/SnackbarContext';
import FrontBackgroundImage from '../images/front_background.jpg';
import FormBox from './building_blocks/FormBox';


const FrontPage = () => {
  const [email, setEmail] = useState('');
  const { openSnackbar } = useContext(SnackbarContext);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    leadsPathTo.save({email: email}).then(result => {
      // envio de e-mail comercial
      
      // snackbar sucesso
      openSnackbar("Muito obrigado pelo seu interesse!", "success");

      // empty fields
      setEmail('');
    }, error => {
      openSnackbar(error.response.data.error, "error");
      console.log("login error", error);
    })
  };

  return (
    <Box>
      <Box
        sx={{
          background: `url(${FrontBackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          position: 'fixed',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: '-1',
        }}></Box>
      
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <FormBox>
          <Typography variant="h4">
            Seja bem-vindo(a) ao TudoPets.app!
          </Typography>
          <Typography variant="subtitle1">
            Encontre facilmente o que precisa no comércio local da sua cidade.
          </Typography>

          <Typography variant="subtitle1" mt={2}>
            Insira seu email para receber o e-mail comercial:
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box>
              <TextField
                label="Email"
                color="primary"
                variant="outlined"
                type="email"
                value={email}
                onChange={handleEmailChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="contained" color="primary" type="submit">
                        Legal!
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </form>
          <Box mt={1}>
            <Link to="/register">Cadastre-se</Link> ou vá para a <Link to="/login">página de login</Link>
          </Box>
        </FormBox>
      </Box>
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={3}>
                {/* <Typography variant="h5" component="h2">
                  Como mencionado anteriormente, a seção de herói é uma boa maneira de atrair a atenção do usuário e transmitir a mensagem principal do seu aplicativo.

                  Introdução: Adicione uma seção de introdução que explique brevemente o propósito do seu aplicativo e como ele pode ajudar os usuários.

                  Recursos Principais: Lista os principais recursos do seu aplicativo e explique como eles podem ser úteis para os usuários.

                  Experiência do Usuário: Mostre exemplos de como os usuários podem interagir com o seu aplicativo, como pesquisar produtos ou serviços, visualizar perfil de empresas, etc.

                  Testimonials: Inclua testemunhos de usuários satisfeitos para dar credibilidade ao seu aplicativo.

                  Contato: Forneça uma seção de contato onde os usuários possam entrar em contato com você ou sua equipe para obter mais informações ou fazer perguntas.
                </Typography> */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Conectando pessoas da indústria animal local em busca de produtos e serviços.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Encontre facilmente o que precisa no comércio local da sua cidade, aumentando o conforto de clientes e fornecedores.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Um aplicativo com foco em UI/UX de alta qualidade.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Taxas competitivas com outros aplicativos.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Disponível em todos os dispositivos.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Receba produtos na sua casa.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {/* Agende serviços com facilidade. */}
                  Cuide da saúde do seu pet com facilidade.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Notificações de ajuda com cuidados para seu pet.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FrontPage;
