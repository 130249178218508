import React, { useState, useContext, useEffect } from 'react';
import { usersPathTo } from '../utils/Api';
import { UserContext } from '../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import FormBox from '../components/building_blocks/FormBox';
import Button from '@mui/material/Button';
import SnackbarContext from '../contexts/SnackbarContext';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';


const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setCurrentUser } = useContext(UserContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'TudoPets - Cadastro';
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // todo: Melhorar cadastro para que quando sucesso, redirecione para a tela interna do app
  const handleSubmit = (e) => {
    e.preventDefault();
    usersPathTo.signup({email: email, password: password}).then(result => {
      setCurrentUser(result.data);
      localStorage.setItem('current_user', JSON.stringify(result.data))
      // openSnackbar("Cadastro realizado com sucesso. Enviamos um e-mail para confirmação", "success");
      navigate("/login");
    }, error => {})
  };

  return (
    <FormBox>
      <Box mt={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography color="primary" variant="h5">
          Crie sua conta
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="center" >
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <TextField label="Email" variant="outlined" type="email" value={email}  onChange={handleEmailChange} fullWidth/>
            </Box>
            <Box mt={2}>
              <TextField label="Senha" variant="outlined" type="password" value={password} onChange={handlePasswordChange} fullWidth/>
            </Box>
            <Box mt={4}>
              <Button variant="contained" type="submit">Criar conta</Button>
            </Box>
          </form>
        </Box>
      </Box>
    </FormBox>
    // <div>
    //   <h2>Cadastrar</h2>
    //   <form onSubmit={handleSubmit}>
    //     <div>
    //       <label>Email:</label>
    //       <input type="email" value={email} onChange={handleEmailChange} />
    //     </div>
    //     <div>
    //       <label>Password:</label>
    //       <input type="password" value={password} onChange={handlePasswordChange} />
    //     </div>
    //     <Button variant="contained" type="submit">Cadastrar</Button>
    //   </form>
    // </div>
  );
};

export default Register;