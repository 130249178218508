import { Api } from './Api'

const tagsPathTo = {
  get: (id) => {
    return Api.request({
      url: '/tags/' + id + '.json',
      method: 'get',
    })
  },
  save: (data) => {
    return Api.request({
      url: '/tags.json',
      method: 'post',
      data: { tag: data}
    })
  },
  update: (id, data) => {
    return Api.request({
      url: '/tags/' + id + '.json',
      method: 'put',
      data: { tag: data }
    })
  },
  delete: (id) => {
    return Api.request({
      url: '/tags/' + id + '.json',
      method: 'delete',
    })
  },
  list: (params) => {
    return Api.request({
      url: '/tags.json',
      method: 'get',
      params
    })
  }

}

export default tagsPathTo