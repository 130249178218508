import { Api } from './Api'

const leadsPathTo = {
  save: (data) => {
    return Api.request({
      url: '/leads.json',
      method: 'post',
      data: data
    })
  }
}


export default leadsPathTo
