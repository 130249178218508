import { Api } from './Api'

const cardsPathTo = {
  
  create: (data) => {
    return Api.request({
      url: '/cards.json',
      method: 'post',
      data: { card: data}
    })
  },

  list: () => {
    return Api.request({
      url: '/cards.json',
      method: 'get',
    })
  },

  delete: (id) => {
    return Api.request({
      url: '/cards/' + id + '.json',
      method: 'delete',
    })
  },

}

export default cardsPathTo