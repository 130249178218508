import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { addressesPathTo } from '../utils/Api';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Switch, TextField, Typography } from '@mui/material';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';
import FormBox from './building_blocks/FormBox';
import SnackbarContext from '../contexts/SnackbarContext';
import axios from 'axios';
import ButtonBase from '@mui/material/ButtonBase';

const AddressForm = () => {
  const { currentUser } = useContext(UserContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [cep, setCep] = useState('');
  const [editMode] = useState(currentUser.default_address ? true : false);
  const [address, setAddress] = useState({
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    localidade: '',
    uf: '',
  });

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
  };

  const handleBuscaApiCall = async (event) => {
    const { name, value, type } = event.target;
    setCep(value);
    if (value.length == 8) { 
        try {
            const response = await axios.get('https://viacep.com.br/ws/' + value + '/json/');
            setAddress(response.data)
          } catch (error) {
            console.error(error);
          }
    }
  };

  const handleAddressChange = (event) => {
    const { name, value, type } = event.target;
    setAddress({...address, [name]: value});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let curr = JSON.parse(localStorage.getItem('current_user'));
    
    if(curr.default_address) {
        addressesPathTo.update(currentUser.default_address.id, address).then(result => {
            curr.default_address = result.data
            localStorage.setItem('current_user', JSON.stringify(curr));
            openSnackbar("Endereço atualizado com sucesso!", "success");
            navigate(`/home`);
          })
    } else {
        addressesPathTo.save({...address, user_id: currentUser.id}).then(result => {
          curr.default_address = result.data
          console.log(result)
          curr.id = result.data.id
          localStorage.setItem('current_user', JSON.stringify(curr));
          openSnackbar("Endereço salvo com sucesso!", "success");
          if(window.location.href.indexOf('switch')) {
            navigate(`/shopping-cart`);
          } else {
            navigate(`/home`);
          }
        })
    }
  };

  return (
    <FormBox>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="baseline">
        <Typography color="primary" variant="h5" sx={{alignSelf: 'center'}}>
          {editMode ? 'Editar endereço' : 'Cadastrar endereço'}
        </Typography>
        <Box style={{width: '100%'}} >
          <form onSubmit={handleSubmit}>
            <Box>
              <Box mt={2}>
                <TextField name="cep" label="Cep" variant="outlined" type="text" value={cep} onChange={handleBuscaApiCall} inputProps={{maxLength: 8}} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2} display="flex" textAlign="left">
                <Box sx={{width: '55%'}}>
                    <TextField name="localidade" label="Cidade" variant="outlined" type="text" value={address.localidade} disabled InputProps={{readOnly: true}} sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
                <Box sx={{width: '40%', marginLeft: '16px'}}>
                    <TextField name="uf" label="Estado" variant="outlined" type="text" value={address.uf} disabled InputProps={{readOnly: true}} fullWidth sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
              </Box>
              <Box mt={2}>
                <TextField name="logradouro" label="Rua" variant="outlined" type="text" value={address.logradouro} disabled InputProps={{readOnly: true}} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2}>
                <TextField name="bairro" label="Bairro" variant="outlined" type="text" value={address.bairro} disabled InputProps={{readOnly: true}} fullWidth sx={{
                  '& .MuiInputBase-input': {
                    fontSize: '14px',
                  },
                }}/>
              </Box>
              <Box mt={2} display="flex" textAlign="left">
                <Box sx={{width: '40%'}}>
                    <TextField name="numero" label="Numero" onChange={handleAddressChange}  variant="outlined" type="text" value={address.numero} fullWidth sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
                <Box sx={{width: '55%',  marginLeft: '16px'}}>
                    <TextField name="complemento" label="Complemento" onChange={handleAddressChange} variant="outlined" type="text" value={address.complemento} sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                    },
                    }}/>
                </Box>
              </Box>
            </Box>
            <Box display="flex" mt={4}>
                <Button sx={{marginLeft: 'auto'}} variant="contained" type="submit">
                    {editMode ? 'Atualizar endereço' : 'Cadastrar endereço'}
                </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </FormBox>
  );
};

export default AddressForm

