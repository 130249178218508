import React, { useState, useContext } from 'react';
import { usersPathTo } from '../utils/Api';
import { UserContext } from '../contexts/UserContext';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import queryString from 'query-string';


const PasswordEdit = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { currentUser } = useContext(UserContext);
  const resetPasswordToken = queryString.parse(useLocation().search).reset_password_token

  if (currentUser) {
    setEmail(currentUser.email);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  const handlePasswordConfirmationChange = (e) => {
    // setEmail(e.target.value);
    setPasswordConfirmation(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmit = (e) => {
    console.log(resetPasswordToken);
    e.preventDefault();
    usersPathTo.reset_password({ 
      user: { email: email, password: password, password_confirmation: passwordConfirmation, reset_password_token: resetPasswordToken }
    }).then(result => {
      // setCurrentUser(result.data.data.user);
      console.log("PasswordEdit", result);
      // localStorage.setItem('current_user', result.data.data)
    }, error => {})
  };

  return (
    <div>
      <h2>PasswordEdit</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={handleEmailChange} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={handlePasswordChange} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={passwordConfirmation} onChange={handlePasswordConfirmationChange} />
        </div>
        <Button variant="contained" type="submit">PasswordEdit</Button>
        <Link to="/register">Cadastrar-se</Link>
      </form>
    </div>
  );
};

export default PasswordEdit;