import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Storefront, Person, Home, Pets, Search, AddBusiness } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';

function Navbar() {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  if (currentUser) {
    return (
      <Box pt={8}>
        <BottomNavigation
          showLabels
          sx={{width: '100%', position: 'fixed', bottom: 0, zIndex: 9999}}
          onChange={(event, newValue) => {
            navigate(`/${newValue}`);
          }}
        >
          <BottomNavigationAction key="home" label="Home" value="home" icon={<Home />}   />
          <BottomNavigationAction key="search" label="Buscar" value="search" icon={<Search />}   />
          <BottomNavigationAction key="mypets" label="Meus Pets" value="mypets" icon={<Pets />}  />
          {currentUser.provider_id && 
            <BottomNavigationAction key="provider" label="Fornecedor" value="provider-dashboard" icon={<Storefront />} />
          }
          {currentUser.role === "admin" && 
            <BottomNavigationAction key="new-provider" label="Novo Fornecedor" value="new-provider" icon={<AddBusiness />} />
          }
          <BottomNavigationAction key="profile" label="Perfil" value="profile" icon={<Person />}  />
        </BottomNavigation>
      </Box>
    );
  }
  
}

export default Navbar;
//     <AppBar position="static">
//       <Container maxWidth="xl">
//         <Toolbar disableGutters sx={{textAlign: 'center', flexgrow: 1}}>
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 2,
//               display: { xs: '', md: 'flex' },
//               fontFamily: 'monospace',
//               fontWeight: 700,
//               letterSpacing: '.3rem',
//               color: 'white',
//               textDecoration: 'none',
//               textAlign: 'center',
//             }}
//           >
//             Tudopets :3
//           </Typography>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// } else {
//   return (
//     <AppBar position="static">
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <Typography
//             variant="h6"
//             noWrap
//             component="a"
//             href="/"
//             sx={{
//               mr: 2,
//               display: { xs: 'none', md: 'flex' },
//               fontFamily: 'monospace',
//               fontWeight: 700,
//               letterSpacing: '.3rem',
//               color: 'inherit',
//               textDecoration: 'none',
//             }}
//           >
//             Tudopets :3
//           </Typography>
//           <button onClick={() => navigate('/profile')}>Profile</button>
//           <button onClick={() => navigate('/inicio')}>Landing Page</button>
//           {currentUser.role === "admin" && <button onClick={() => navigate('/new-provider')}>Novo fornecedor</button>}
//           {currentUser.provider_id && <button onClick={() => navigate('/provider-dashboard')}>Painel do Fornecedor</button>}
//           <Tooltip title="Open settings">
//             <IconButton onClick={() => navigate('/profile')} sx={{ p: 0 }}>
//               <Avatar alt={currentUser && `${currentUser.name ? currentUser.name : currentUser.email}`} src="/static/images/avatar/2.jpg" />
//             </IconButton>
//           </Tooltip>
//         </Toolbar>
//       </Container>
//     </AppBar>