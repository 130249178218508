import React, { useContext, useEffect } from 'react';
import { ProviderProductsContext } from '../contexts/ProviderProductsContext';
import productsPathTo from '../utils/Api/productsPathTo';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonBase, Checkbox, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { currency } from './building_blocks/Currency';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SnackbarContext from '../contexts/SnackbarContext';

const ProviderProductList = () => {
  const { providerProductList, updateProviderProductList, updateProviderProduct } = useContext(ProviderProductsContext);
  const navigate = useNavigate();

  const { openSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    if (providerProductList.length === 0) {
      productsPathTo.list().then((response) => {
        updateProviderProductList(response.data)
      })
    }
  }, [])

  const handlePublishedChange = async (event, product) => {
    // ainda não está smooth. até o retorno da chamada de atualização, a lista de produtos ainda não foi atualizada. Mas está funcionando
    event.preventDefault();
    if (product.been_published === true || (product.been_published === false && window.confirm("Tem certeza que deseja publicar este produto? Após publicação, o título não poderá ser alterado."))) {
    
      const { checked } = event.target;

      try {
        const updatedProduct = await productsPathTo.update(product.id, { published: checked });
        updateProviderProduct(updatedProduct.data);
        checked === true ? openSnackbar("Produto publicado na loja!", "success") : openSnackbar("Produto retirado da loja!", "success")
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <AddTaskIcon aria-label="Publicado" sx={{color: 'orange', marginRight: '8px', width: '18px'}}/><Typography variant="body2" textAlign="left"><small>Produto publicado</small></Typography>
      </Box>
      {providerProductList.map((product, index) => (
        <Box key={product.id}>
          <Box m={2} display="flex" sx={{width: "100%", height: '100%'}}>
              <Box sx={{width: "100%"}}>
                  <Typography mr={2} textAlign="left" variant="body2"><b>{product.title}</b></Typography>
                  <Typography mt={1} mb={1} sx={{color: '#555'}} textAlign="left" variant="body2"><b>{currency(product.price)}</b></Typography>
                  <Box display="flex" alignItems="end">
                    { product.published &&
                      <Typography><AddTaskIcon sx={{color: 'orange', marginRight: '8px'}}/></Typography>
                    }
                    { !product.published &&
                      <Typography><AddTaskIcon sx={{color: 'gray', marginRight: '8px'}}/></Typography>
                    }
                    <Switch
                      checked={product.published}
                      onChange={(event) => handlePublishedChange(event, product)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <ButtonBase sx={{marginLeft: 'auto', marginRight: '28px', alignSelf: 'center'}} onClick={() => navigate(`/provider/products/${product.id}`)}>
                      <Typography color="primary" variant="body2">Editar</Typography>
                    </ButtonBase>
                  </Box>
              </Box>
          </Box>
          {providerProductList.length !== index + 1 && (
              <Divider variant="middle"/>
          )}
        </Box>
      ))}
    </Box>
  )
  // return (
  //   <Box>
  //         {providerProductList.map(({ id, title, price, published }) => (
  //           {/* <TableCell onClick={() => navigate(`/provider/products/${id}`)}>
  //             { /* TODO: mostrar foto principal do produto */ }
  //           {/* <TableCell onClick={() => navigate(`/provider/products/${id}`)}>{title}</TableCell>
  //           <TableCell onClick={() => navigate(`/provider/products/${id}`)}>{`R$${price}`}</TableCell> */}
  //           <Switch
  //             checked={published}
  //             onChange={(event) => handlePublishedChange(event, id)}
  //             inputProps={{ 'aria-label': 'controlled' }}
  //           />
  //         })
  //   </Box>
  // )
}

export default ProviderProductList
