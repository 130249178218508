import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import Button from '@mui/material/Button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ProviderProductList from './ProviderProductList';
import { Avatar, Box, Divider, IconButton, Typography, useMediaQuery, Switch } from '@mui/material';
import { providersPathTo } from '../utils/Api';
import SnackbarContext from '../contexts/SnackbarContext';
import FormBox from './building_blocks/FormBox';
import { apiBaseUrl } from '../utils/Api/Api';

const ProviderDashboard = () => {
  const { currentUser } = useContext(UserContext);
  const [provider, setProvider] = useState();
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onboarding_completed = searchParams.get('onboarding_completed');

  useEffect(() => {
    if (currentUser.provider_id) {
      providersPathTo.get({id: currentUser.provider_id}).then(result => {
        setProvider(result.data);
        // todo: cache provider in context
      });
    }
  }, [currentUser.provider_id]);

  useEffect(() => {
    document.title = 'TudoPets - Painel do fornecedor';
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onload = () => {
      providersPathTo.update_logo_image(provider.id, {business_logo: reader.result.split(',')[1]}).then(result => {
        setProvider({...provider, ['business_logo']: result.data.business_logo});
        openSnackbar("Imagem atualizada com sucesso!", "success");
      })
    }
    reader.readAsDataURL(file);
  }

  const handleAvatarClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = handleImageChange;
    input.click();
  }

  const handleStripeAccountOnboarding = () => {
    if(provider) {
      providersPathTo.stripe_account_onboarding(provider.id).then(result => {
        window.open(result.data.url); // https://connect.stripe.com/setup/s/acct_1PaPTnQkIR2KFRv1/WSleUDG7PiTm
      })
    }
  }

  const handlePublishedChange = async (event, provider) => {
    event.preventDefault();
    const { checked } = event.target;

    try {
      const updatedProvider = await providersPathTo.update(provider.id, { published: checked });
      setProvider(updatedProvider.data);
      checked === true ? openSnackbar("Fornecedor publicado na loja!", "success") : openSnackbar("Fornecedor retirado da loja!", "success")
    } catch (error) {
      console.error('Error updating provider:', error);
    }
  };

  if (!provider) {
    return "carregando...";
  }

  return (
    <Box>
      {((provider && provider.stripe_onboarding) || !onboarding_completed) && (
        <FormBox>
          <Box>
            <Typography>Para que o fornecedor possa receber pagamentos, é preciso completar o onboarding do Stripe</Typography>
            <Button variant="contained" onClick={() => handleStripeAccountOnboarding()}>Completar cadastro</Button>
          </Box>
        </FormBox>
      )}

      <FormBox display="flex" flexDirection="column">
        <Box>
          <Switch
            checked={provider.published}
            onChange={(event) => handlePublishedChange(event, provider)}
            inputProps={{ 'aria-label': 'controlled' }}
          />

          <Typography color="primary" variant="h5">
            <b>Painel do fornecedor</b>
          </Typography>
          { provider && (
          <Box>
            <IconButton mt={2} onClick={handleAvatarClick}>
              <Avatar alt={currentUser && `${currentUser.name ? currentUser.name : currentUser.email}`} src={provider.business_logo ? `${apiBaseUrl}${provider.business_logo}` : '/static/images/avatar/2.jpg'} />
            </IconButton>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography><b>Nome:</b> {provider.name}</Typography>
              <Typography><b>Descrição:</b> {provider.description}</Typography>
              <Typography><b>Cnpj:</b> {provider.cnpj}</Typography>
              <Typography><b>Endereço:</b> {provider.address.logradouro}, {provider.address.numero}</Typography>
              <Typography><b>Telefone:</b> {provider.phone}</Typography>
              
              <Button variant="outlined" color="primary" onClick={() => navigate(`/provider/${provider.id}/details`) }>
                Alterar dados
              </Button>
            </Box>
          </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1} mt={2}>
          <Typography color="primary" variant="h5">
            <b>Meus produtos</b>
          </Typography>
          <ProviderProductList/>

          <Button variant="outlined" color="primary" onClick={() => navigate(`/provider/products/new`) }>
            Criar novo produto
          </Button>
        </Box>
      </FormBox>
    </Box>
  );
};

export default ProviderDashboard