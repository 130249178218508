import React, { createContext, useEffect, useState } from 'react';

export const ShoppingCartProductsContext = createContext();

export const ShoppingCartProductsProvider = ({ children }) => {
  const [shoppingCartProducts, setShoppingCartProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const storedProducts = JSON.parse(localStorage.getItem('shoppingCartProducts'));


  useEffect(() => {
    if (storedProducts && storedProducts.length > 0) {
      setShoppingCartProducts(storedProducts);
    }
  }, [])

  
  useEffect(() => {
    const updateTotal = () => {
      if (storedProducts.length > 0) {
        const amount = shoppingCartProducts.reduce(
            (accumulator, product) => accumulator + product.cartQuantity * product.price,
            0
            )
        setTotalAmount(amount)
        localStorage.setItem("totalAmount", amount);
  
        const quantity = shoppingCartProducts.reduce(
            (accumulator, product) => accumulator + product.cartQuantity,
            0
            )
        setTotalQuantity(quantity)
        localStorage.setItem("totalQuantity", quantity);
      };
    }

    if (shoppingCartProducts && shoppingCartProducts.length > 0) {
      updateTotal();
    }
  }, [shoppingCartProducts]);

  return (
    <ShoppingCartProductsContext.Provider value={{ shoppingCartProducts, totalAmount, setShoppingCartProducts, setTotalAmount, setTotalQuantity, totalQuantity }}>
      {children}
    </ShoppingCartProductsContext.Provider>
  );
};