import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "./contexts/UserContext";
import { ProviderProductsProvider } from "./contexts/ProviderProductsContext";
import { TagsProvider } from "./contexts/TagsContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <UserProvider>
        <TagsProvider>
          <ProviderProductsProvider>
            <App />
          </ProviderProductsProvider>
        </TagsProvider>
      </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
