import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
// import { SnackbarContext } from '../contexts/SnackbarContext';

const ProtectedRoute = () => {
  const { currentUser } = useContext(UserContext);
    return (
      currentUser ? <Outlet /> : <Navigate to="/login" />
    )
};

export default ProtectedRoute;