import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, Card, CardActionArea, CardContent, Divider, IconButton, Typography  } from "@mui/material";
import FormBox from './building_blocks/FormBox';
import { useContext } from 'react';
import SnackbarContext from '../contexts/SnackbarContext';
import cardsPathTo from '../utils/Api/cardsPathTo';
import { useEffect } from 'react';
import { useState } from 'react';
import visa from '../images/visa_card.png';
import master from '../images/master_card.png';
import TrashIcon from '../images/trash.png';
import { useNavigate } from 'react-router-dom';

const PaymentMethods = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useContext(SnackbarContext);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const isSwitch = window.location.href.indexOf('switch') != -1

  useEffect(() => {
    cardsPathTo.list().then(result => {
      setPaymentMethods(result.data);
    });
  }, [])

  const handleDelete = (id, index) => {
    cardsPathTo.delete(id).then(() => {
      setPaymentMethods([...paymentMethods.slice(0, index), ...paymentMethods.slice(index + 1)]);
      openSnackbar("Cartão removido com sucesso!", "success");
    })
  }

  const selectPaymentMethod = (index) => {
    localStorage.setItem('payment_method', index);
    navigate('/shopping-cart');
    openSnackbar("Cartão trocado com sucesso!", "success");
  }
  
  return (
    <Box>
      <Typography sx={{marginTop: '18px'}} color="primary" variant="h5">
        <b>Formas de pagamento</b>
      </Typography>
      {paymentMethods.map((paymentMethod, index) => (
        <FormBox display="flex" flexDirection="column">
          <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Box>
              {paymentMethod.card.brand === 'visa' ? <img src={visa} style={{width: '40px'}}/> : <img src={master} style={{width: '40px'}}/>}
            </Box>
            <Box flexGrow={1} ml={2} textAlign="left">
              <Typography sx={{fontSize: '14px', textTransform: 'capitalize'}}>{paymentMethod.card.brand} &#9679; Crédito</Typography>
              <Typography sx={{fontSize: '14px'}} color="gray">**** {paymentMethod.card.last4}</Typography>
            </Box>
            <Box flexGrow={1} ml={2} textAlign="right">
            { !isSwitch &&
                <IconButton onClick={() => handleDelete(paymentMethod.id, index)}>
                  <img src={TrashIcon} style={{width: '20px'}}/>
                </IconButton>
            }
            { isSwitch &&
                <ButtonBase onClick={() => selectPaymentMethod(index)}>
                  <Typography fontSize={14} color="orange">Selecionar</Typography>
                </ButtonBase>
            }
            </Box>
          </Box>
        </FormBox>
      ))}
      {!isSwitch &&
        <Typography color="primary">
          <ButtonBase onClick={() => navigate(`/new-card`) }>
            Adicionar novo cartão
          </ButtonBase>
        </Typography>
      }
    </Box>
  );
};

export default PaymentMethods;
