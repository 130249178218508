import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, Card, CardActionArea, CardContent, Divider, Typography  } from "@mui/material";
import FormBox from './building_blocks/FormBox';
import { currency } from './building_blocks/Currency';
import { apiBaseUrl } from '../utils/Api/Api';
import ProductPhotoPlaceholder from '../images/placeholder_product_image.png';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import cardsPathTo from '../utils/Api/cardsPathTo';
import SnackbarContext from '../contexts/SnackbarContext';

const NewCard = () => {
  const { openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    const cardElement = elements.getElement(CardElement);
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (!error) {
      const paymentMethodId = paymentMethod.id;
      cardsPathTo.create({payment_method_id: paymentMethodId}).then(result => {
        openSnackbar("Cartão adicionado com sucesso!", "success");
        navigate('/payment-methods');
      })
    } else {
      console.log('Error creating payment method:', error);
    }
  };

  return (
    <Box>
      <FormBox display="flex" flexDirection="column">
        <CardElement options={{
          hidePostalCode: true,
          country: 'BR',
          currency: 'BRL',
        }}/>
        <Box mt={2} sx={{display: 'flex', marginLeft: 'auto'}}>
          <Button onClick={handleSubmit} sx={{marginLeft: 'auto'}} variant="contained" type="button">Adicionar cartão</Button>
        </Box>
      </FormBox>
    </Box>
  );
};

export default NewCard;
