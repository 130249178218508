import { Api } from './Api'

const providersPathTo = {
  get: (options) => {
    return Api.request({
      url: '/providers/' + options.id + '.json',
      method: 'get',
      params: options.params
    })
  },
  list: (options) => {
    return Api.request({
      url: '/providers.json',
      method: 'get'
    })
  },
  update: (id, data) => {
    return Api.request({
      url: '/providers/' + id + '.json',
      method: 'put',
      data: { provider: data }
    })
  },
  update_logo_image: (id, data) => {
    return Api.request({
      url: '/providers/' + id + '/update_logo_image.json',
      method: 'put',
      data: { provider: data }
    })
  },
  provider_with_products: (options) => {
    return Api.request({
      url: '/providers/' + options.id + '/provider_with_products.json',
      method: 'get',
      params: options.params
    })
  },
  save: (data) => {
    return Api.request({
      url: '/providers.json',
      method: 'post',
      data: { provider: data }
    })
  },
  stripe_account_onboarding: (id) => {
    return Api.request({
      url: `/providers/${id}/stripe_account_onboarding.json`,
      method: 'get'
    })
  }
}

export default providersPathTo